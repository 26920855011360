export const orderStatusRequests = {
    methods: {
        setPreCancel(orderId, text, doSend) {
            return this.$http.put('orders/discusing', {
                'order_id': orderId,
                'text': text,
                'is_send': doSend
            });
        },
        setCancel(orderId, text, doSend) {
            return this.$http.put('orders/cancel', {
                'order_id': orderId,
                'text': text,
                'is_send': doSend
            });
        },
        setPause(orderId, text) {
            return this.$http.put('orders/pause', {
                'order_id': orderId,
                'text': text,
            });
        },
        setStart(orderId, doSend) {
            return this.$http.put('orders/start', {
                'order_id': orderId,
                'is_send': doSend,
            });
        }
    }
};