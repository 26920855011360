<template>
    <b-modal :id="windowId" title="Update Order">
        <b-container fluid>
            <div class="form-group">
                <amdin-input v-model="email" title="Email" :disabled="true" />
                <amdin-input v-if="phone" v-model="phone" title="Phone" :disabled="true" />
                <amdin-input v-model="firstName" title="First Name" :disabled="true" />
                <amdin-input v-model="lastName" title="Last Name" :disabled="true" />
                <amdin-input v-model="city" title="City" :disabled="true" />
                <amdin-input v-model="country" title="Country" :disabled="true" />
                <amdin-input v-model="youtubeUrl" title="Youtube Url" :errors="serverErrors" />
                <amdin-input v-model="packageTitle" title="Package" :disabled="true" />
                <amdin-input v-model="packageViews" title="Views" :disabled="true" />
                <amdin-input v-model="packagePrice" title="Price" :disabled="true" />
                <amdin-input v-model="paymentId" title="Payment Id" name="payment_id" :disabled="true"   />
                <amdin-input v-model="paymentTotal" title="Payment Total" name="payment_total" :disabled="true" />
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Submit</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import amdinInput from './../Form/dnd-admin-input';

export default {
    components: {amdinInput},
    data() {
        return {
            windowId: 'updatePreCancelOrderWindow',
            email: null,
            phone: null,
            firstName: null,
            lastName: null,
            city: null,
            country: null,
            youtubeUrl: null,

            external_id: null,

            packageSlug: null,
            packageTitle: null,
            packageViews: null,
            packagePrice: null,
            
            paymentId: null,
            paymentTotal: null,

            serverErrors: []
        }
    },
    props: {
        orderId: {
            type: Number,
            default: 0
        }
    },
    methods: {
        show() {
            this.getOrder();
            this.serverErrors = [];
            this.$bvModal.show(this.windowId);
        },
        save() {
            this.$http.put('orders/update/pre-canceled', {
                'external_id': this.external_id,
                'youtube_video_url': this.youtubeUrl
            }).then(this.responseSuccess, this.responseError);
        },
        responseSuccess(res) {
            this.$bvModal.hide(this.windowId);
            this.$emit('saved', res.data.new_id);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.serverErrors = res.response.data.errors;
            }
            this.$emit('saved', false);
        },
        getOrder() {
            this.$http({ url: 'order/' + this.orderId, method: 'GET'})
                .then(res => {
                    this.email = res.data.order.email;
                    this.phone = res.data.order.phone;
                    this.firstName = res.data.order.first_name;
                    this.lastName = res.data.order.last_name;
                    this.city = res.data.order.city;
                    this.country = res.data.order.country;
                    this.youtubeUrl = res.data.order.youtube_video_url;
                    this.external_id = res.data.order.external_order_id;

                    this.packageSlug = res.data.order.package_slug;
                    this.paymentId = res.data.order.payment_id;

                    this.$http({ url: 'packages/slug/' + this.packageSlug, method: 'GET'})
                        .then((resp) => { 
                            this.packageTitle = resp.data.data.title;
                            this.packagePrice = +resp.data.data.price;
                            this.paymentTotal = +resp.data.data.price;
                            this.packageViews = parseInt(resp.data.data.views_amount).toLocaleString('ru-RU');
                        });
                });
        }
    }
}
</script>
