<template>
    <b-modal :id="windowId" title="Cancel Order">
        <b-container fluid>
            <admin-textarea v-model="reasonText" title="Write the reason:" name="text" :errors="reasonErrors" />
            <b-form-group v-if="showOptions" label="Cancel Options" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="cancelOption" :aria-describedby="ariaDescribedby" name="cancel-type" value="cancel-send">
                    Set status <b>Canceled</b> and <b>send</b> a notification to the customer.
                </b-form-radio>
                <b-form-radio v-model="cancelOption" :aria-describedby="ariaDescribedby" name="cancel-type" value="cancel-not-send">
                    Set status <b>Canceled</b> and <b>do not send</b> a notification to the customer.
                </b-form-radio>
            </b-form-group>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Submit</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import adminTextarea from './../Form/dnd-admin-textarea';
import { orderStatusRequests } from './../../mixins/order-status-requests.mixin';

export default {
    components: {adminTextarea},
    mixins: [orderStatusRequests],
    data() {
        return {
            windowId: 'cancel-order-window',
            reasonText: null,
            reasonErrors: [],
            cancelOption: 'cancel-send'
        }
    },
    props: {
        orderId: {
            type: Number,
            default: 0
        },
        showOptions: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        show() {
            this.reasonErrors = [];
            this.reasonText = null;
            this.$bvModal.show(this.windowId);
        },
        save() {
            this.setCancel(
                this.orderId,
                this.reasonText,
                this.cancelOption == 'cancel-send' && this.showOptions
            ).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.windowId);
            this.$emit('saved', true);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.reasonErrors = res.response.data.errors;
            }
            this.$emit('saved', false);
        }
    }
}
</script>
