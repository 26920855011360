<template>
    <b-modal :id="windowId" title="Start Promotion">
        <b-container fluid>
            <p>Are we ready to promote this video?</p>
            <b-form-group v-if="showOptions" label="Customer notification options:" v-slot="{ ariaDescribedby }">
                <b-form-radio v-model="option" :aria-describedby="ariaDescribedby" name="cancel-type" value="start-promo-send">
                    Set status <b>In Process</b> and <b>send</b> a notification to the customer.
                </b-form-radio>
                <b-form-radio v-model="option" :aria-describedby="ariaDescribedby" name="cancel-type" value="start-promo-not-send">
                    Set status <b>In Process</b> and <b>do not send</b> a notification to the customer.
                </b-form-radio>
            </b-form-group>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Submit</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { orderStatusRequests } from './../../mixins/order-status-requests.mixin';

export default {
    mixins: [orderStatusRequests],
    data() {
        return {
            windowId: 'start-promotion-window',
            reasonText: null,
            reasonErrors: [],
            option: 'start-promo-send'
        }
    },
    props: {
        orderId: {
            type: Number,
            default: 0
        },
        showOptions: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        show() {
            this.reasonErrors = [];
            this.reasonText = null;
            this.$bvModal.show(this.windowId);
        },
        save() {
            this.setStart(
                this.orderId,
                this.option == 'start-promo-send' && this.showOptions
            ).then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.windowId);
            this.$emit('saved', true);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.reasonErrors = res.response.data.errors;
            }
            this.$emit('saved', false);
        }
    }
}
</script>
