<template>
    <b-modal :id="windowId" title="Pause Order">
        <b-container fluid>
            <admin-textarea v-model="reasonText" title="Write the reason:" name="text" :errors="reasonErrors" />
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="primary" @click="save">Submit</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import adminTextarea from './../Form/dnd-admin-textarea';
import { orderStatusRequests } from './../../mixins/order-status-requests.mixin';

export default {
    components: {adminTextarea},
    mixins: [orderStatusRequests],
    data() {
        return {
            windowId: 'pause-order-window',
            reasonText: null,
            reasonErrors: []
        }
    },
    props: {
        orderId: {
            type: Number,
            default: 0
        }
    },
    methods: {
        show() {
            this.reasonErrors = [];
            this.reasonText = null;
            this.$bvModal.show(this.windowId);
        },
        save() {
            this.setPause(this.orderId, this.reasonText)
                .then(this.responseSuccess, this.responseError);
        },
        responseSuccess() {
            this.$bvModal.hide(this.windowId);
            this.$emit('saved', true);
        },
        responseError(res) {
            if(res.response.data.hasOwnProperty('errors')) {
                this.reasonErrors = res.response.data.errors;
            }
            this.reasonErrors = this.getValidationError(res, 'reasonText');
            this.$emit('saved', false);
        }
    }
}
</script>
